
import React from 'react';
import {
  KakaoCounselRightButton,
  Overlay,
  RefundBottomModalContainer, RefundCancelLeftButton, RefundTwoButtonContainer, SubTitleSpan, TitleContainer,
} from './styles';
import { JSP_URL } from '../../../../constants/baseConstants';
import { showWebBrowser } from '../../../../utils/deviceUtils';
import { Caption1, Headline } from 'styles/typography';

interface RefundBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  usageStatus: string;
}

const RefundBottomModal: React.FC<RefundBottomModalProps> = ({ isVisible, onClose, usageStatus = '' }) => {
  const KakaoUrl = 'https://pf.kakao.com/_UxdeGK/chat'

  if (!isVisible) return null;

  const handleClose = () => {
    onClose()
  }

  return (
    <>
      <Overlay onClick={handleClose} />
      <RefundBottomModalContainer isVisible={isVisible}>
        <TitleContainer>
          <Headline weight={'medium'} color={'black1'}>환불 요청 하시나요?</Headline>
          <Caption1 weight={'medium'} style={{ color: '#6b6b6b' }}>된 주차권입니다. 환불 요청은 담당자 문의 후 처리 가능해요.</Caption1>
          <RefundTwoButtonContainer>
            <RefundCancelLeftButton onClick={() => {
              handleClose()
            }}>돌아가기</RefundCancelLeftButton>
            <KakaoCounselRightButton onClick={() => {
              if (!KakaoUrl) {
                console.error('KakaoURL이 정의되지 않음')
                return
              }
              showWebBrowser(KakaoUrl)
            }}>카카오톡 문의하기</KakaoCounselRightButton>
          </RefundTwoButtonContainer>
        </TitleContainer>
      </RefundBottomModalContainer>
    </>
  );
}

export default RefundBottomModal